$main-color:#d8bfd8;
$main-shadow:#696969;

.site-container {
    cursor: default;
    background-color: #ebebeb;
    color: #696969;
    min-height: 100%;
    height: fit-content;
    padding: 1rem 0;
    text-transform: capitalize;


    h1,
    h2,
    h3 {
        text-transform: uppercase;

    }

    h1 {
        color: $main-color;
        display: inline-block;
        z-index: 0;
        text-shadow: 1px 1px 1px $main-shadow, 2px 2px 1px $main-shadow, 3px 3px 1px $main-shadow, 5px 4px 4px $main-shadow;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            border-radius: 4px;
            background-color: $main-color;
            box-shadow: 1px 1px 1px $main-shadow, 2px 2px 1px $main-shadow, 3px 3px 2px $main-shadow;
            bottom: -4px;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform .3s ease-in-out;

        }

        &:hover {
            &::before {
                transform: scaleX(1);
                transform-origin: left;
            }
        }


    }

    .site-details {
        text-align: start;
        justify-content: center;

        >* {
            padding: 1rem 0;
        }

        li {

            &:hover {
                transform: translateX(3px);
                transition: transform .1s linear
            }
        }

        h2 {
            color: #333;
            font-family: "proxima nova bold",
                "Helvetica Neue",
                Helvetica,
                Arial,
                sans-serif;
        }

        h3 {
            color: #778899;
        }

        .img-container {
            position: relative;
            width: 100%;
            padding-top: 50%;
            margin: 1rem auto;

            //     >.block {
            //         position: absolute;
            //         height: 100%;
            //         width: 100%;
            //         perspective: 1000px;
            //         top: 0;
            //         left: 0;


            //     >.side {
            //         position: absolute;
            //         top: 0;
            //         left: 0;
            //         // background-image: url('../../../utils/img/websites/cvBuilder.jpg');
            //         background-size: auto 100%;
            //         box-shadow: -1vw .5vw 1vw rgba(0, 0, 0, .3);

            //         &.-main {
            //             height: 100%;
            //             width: 88%;
            //             transform: rotateY(30deg);
            //             transform-origin: 0 50%;
            //             background-position: 100% 0;

            //         }

            //         &.-left {
            //             height: 100%;
            //             width: 15%;
            //             transform-origin: 0 50%;
            //             transform: rotateY(-60deg) translateX(-100%);
            //             filter: brightness(40%);
            //             background-position: 0% 0;

            //         }
            //     }
            // }
            >.img-paper {
                position: absolute;
                height: 100%;
                width: 100%;
                perspective: 1000px;
                top: 0;
                left: 0;

                >.part {
                    position: absolute;
                    border-left: 1px solid gray;
                    top: 0;
                    left: 0;
                    background-size: auto 100%;
                    box-shadow: 1vw 0.5vw 1vw rgba(0, 0, 0, 0.3);
                    height: 100%;
                    width: 25.5%;
                    transition: transform 1s linear;

                    &.left {
                        left: 0%;
                        transform-origin: 110% 50%;
                        transform: rotateY(45deg) translateX(38%);
                        background-position: 0% 50%;
                        box-shadow: -1vw 0.5vw 1vw rgb(0 0 0 / 30%);

                    }

                    &.mid-left {
                        left: 25.5%;
                        transform: rotateY(-45deg) translateX(21%);
                        background-position: calc(100% / 3 * 1) 50%;
                    }

                    &.mid-right {
                        left: 51%;
                        transform: rotateY(45deg) translateX(-21%);
                        background-position: calc(100% / 3 * 2) 50%;
                    }

                    &.right {
                        left: 76.5%;
                        transform-origin: -10% 50%;
                        transform: rotateY(-45deg) translateX(-38%);
                        background-position: calc(100% / 3 * 3) 50%;
                    }
                }

                &:hover {
                    >.part {

                        &.left,
                        &.mid-left,
                        &.mid-right,
                        &.right {
                            transform: none;
                            border: none;
                            box-shadow: 1vw 0.5vw 1vw rgba(0, 0, 0, 0.3);

                        }

                    }
                }
            }

        }

        .tech {
            background-color: #00bfff;
            color: #000;
            box-shadow: 3px 5px 4px #212529;
            display: inline-block;
            padding: 1rem;
            margin: 0.5rem;
            border-radius: 1rem;

            &:hover {
                color: #00bfff;
                background-color: #000;
                // transform: translateY(3px);
                transition: transform .1s linear
            }
        }
    }
}