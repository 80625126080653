.upDown {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    animation: upDownArrow 2s infinite;

    // scroll down icon 
    .up,
    .down {
        margin: auto;
        cursor: pointer;
        height: 2.5rem;
        width: auto;
        padding: 0 .4rem;
        filter: drop-shadow(1px 1px 2px yellow);
    }

    @keyframes upDownArrow {
        50% {
            transform: translateY(-.25rem);
        }
    }
}