.intro {
    background: #424d60;
    background-image: url("../../../utils/img/Backgrounds/bg31.jpg");
    background-repeat: round;

    .intro-card {
        border-radius: 10px;

        position: relative;

        // box-shadow: inset 20px 20px 60px #d994a0,
        //     inset -20px -20px 60px #ffc8d8;
        .animation-row {
            position: absolute;
            top: 0%;
            left: 0%;
            // flex-wrap: nowrap;
            width: 100%;
            // transform: translate(-50%, 0%);
            mask-image: radial-gradient(circle, black 0%, rgba(0, 0, 0, 0.05) 50%);
            mask-position: 0vw 0vh;
            animation: animation-bg 10s linear 0s infinite normal forwards;
            height: 80%;
            overflow: hidden;
            padding: 0;
            margin: 0;
            // opacity: .2;
            // @media only screen and (max-width: 600px) {
            //     display: none;

            // }

            .animation-col {
                position: relative;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    filter: drop-shadow(3px 3px 3px gray) drop-shadow(2px 4px 6px black);
                }

                .animation-bg {
                    position: absolute;
                    // width: 100%;
                    left: 50%;
                    // top: 50%;
                    // transform: translate(-50%, -50%);
                    top: 0%;
                    transform: translate(-50%, 0%);

                }

                .animation-bg2 {
                    max-width: 50%;
                    max-height: 50%;
                    position: absolute;
                    left: 50%;
                    // top: 50%;
                    // transform: translate(-90%, -90%);
                    top: 0%;
                    transform: translate(-90%, 12%);

                    animation: animation-bg2 30s linear 0s infinite normal forwards;
                }

                .animation-bg3 {
                    position: absolute;
                    max-width: 50%;
                    max-height: 50%;
                    left: 50%;
                    // top: 50%;
                    // transform: translate(-23%, -24%);
                    top: 0%;
                    transform: translate(-23%, 77%);

                    animation: animation-bg3 30s linear 0s infinite normal forwards;
                }

                .animation-bg4 {
                    position: absolute;
                    // width: 100%;
                    left: 50%;
                    // top: 50%;
                    // transform: translate(-50%, -50%);
                    top: 0%;
                    transform: translate(-50%, 0%);
                }

                .animation-bg5 {
                    position: absolute;
                    max-width: 70%;
                    max-height: 70%;
                    left: 50%;
                    // top: 50%;
                    // transform: translate(-50%, -50%);
                    top: 0%;
                    transform: translate(-50%, 35%);
                }

                .animation-bg6 {
                    position: absolute;
                    max-width: 37.5%;
                    max-height: 37.5%;
                    left: 50%;
                    // top: 50%;
                    // transform: translate(-50%, -135%);
                    top: 0%;
                    transform: translate(-50%, 18%);


                    animation: animation-bg6 10s linear 0s infinite normal forwards;
                }
            }
        }


        .content {
            height: calc(100% - 1.5rem);
            padding: 0 .5rem;

            // photo column
            .photo {
                position: relative;
                transition: transform 2s;

                &:hover {
                    transform: scale(1.05);
                }

                .myPhoto {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: calc(70vh - 6rem);
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: auto;
                    filter: drop-shadow(10px 10px 10px #555);
                    height: 60vh;
                }
            }

            // paragraph column 
            .paragraph-col {
                text-align: left;
                position: relative;

                .paragraph {
                    transform: translateY(-50%) rotateX(20deg) rotateY(-10deg) rotateZ(3deg);
                    color: #ed143d;
                    position: absolute;
                    top: 50%;
                    text-shadow: 0px 1px 0px #000,
                        0px 2px 0px #000,
                        0px 3px 0px #000,
                        0px 6px 3px rgb(0 0 0 / 40%);

                    .welcome {
                        color: #fff;
                        text-shadow: 1px 1px 1px #919191,
                            1px 2px 1px #919191,
                            1px 3px 1px #919191,
                            1px 4px 1px #919191,
                            1px 5px 1px #919191,
                            1px 6px 1px #919191,
                            1px 10px 4px rgba(16, 16, 16, 0.4),
                            1px 12px 5px rgba(16, 16, 16, 0.2);
                    }
                }
            }


        }


    }

    // animation when section come to view
    &.active {
        .myPhoto {
            animation: myAnim 2s ease 0s 1 normal forwards;

        }
    }

    // keyframes


    @keyframes myAnim {
        0% {
            transform: translate(-50%, -50%) rotate3d(1, 0, 0, 90deg);
            transition-timing-function: ease-in;
            opacity: 0;
        }

        40% {
            transform: translate(-50%, -50%) rotate3d(1, 0, 0, -20deg);
            transition-timing-function: ease-in;

        }

        60% {
            transform: translate(-50%, -50%) rotate3d(1, 0, 0, 10deg);
            opacity: 1;

        }

        80% {
            transform: translate(-50%, -50%) rotate3d(1, 0, 0, -5deg);

        }

        100% {
            transform: translate(-50%, -50%);
            transition-timing-function: ease-in;

        }
    }

    @keyframes animation-bg {
        0% {
            mask-position: -50vw;

        }

        100% {
            mask-position: 50vw;

        }
    }

    @keyframes animation-bg2 {
        0% {
            // transform: translate(-90%, -90%) rotate(0deg);
            transform: translate(-90%, 15%) rotate(0deg) scaleX(-1);

        }

        100% {
            // transform: translate(-90%, -90%) rotate(360deg);
            transform: translate(-90%, 15%) rotate(360deg) scaleX(-1);

        }
    }

    @keyframes animation-bg3 {
        0% {
            // transform: translate(-23%, -24%) rotate(360deg);
            transform: translate(-22%, 81%) rotate(360deg);

        }

        100% {
            // transform: translate(-23%, -24%) rotate(0deg);
            transform: translate(-22%, 81%) rotate(0deg);

        }
    }

    @keyframes animation-bg6 {
        0% {
            // transform: translate(-50%, -135%) rotate(360deg);
            transform: translate(-50%, 18%) rotate(360deg);

        }

        100% {
            // transform: translate(-50%, -135%) rotate(0deg);
            transform: translate(-50%, 18%) rotate(0deg);


        }
    }
}