.portfolio.section {
    background: #A0E7E5;
    // box-shadow: inset 20px 20px 60px #88c4c3,
    //     inset -20px -20px 60px #b8ffff;

    .topic {
        color: #008080;
    }

    .section-card {
        .item-container {
            width: 100%;

            .subcontainer {
                .details-paragraph {
                    padding: 5px;
                    bottom: 15%;
                    width: 80%;
                }
            }
        }



        // @keyframes description {
        //     0% {
        //         transform: skew(-20deg, 0deg);
        //     }

        //     100% {
        //         transform: skew(0);

        //     }
        // }
    }

}