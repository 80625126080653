@font-face {
  font-family: "proxima nova bold";
  src: url("/utils/fonts/FontsFree-Net-Proxima-Nova-Bold.otf") format("opentype");
}

html {
  font-size: 14px;

  @media (min-width:768px) {
    font-size: 16px;
  }

  @media (min-width:1024px) {
    font-size: 18px;
  }

  @media (min-width:1280px) {
    font-size: 20px;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}