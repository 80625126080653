$popoverColor:#f39174bf;
$popoverShadow:#f57453bf;
// $popoverColor:rgba(208, 2, 27, 0.75);
$triangleWidth:2rem;

.contact {
    background: #FBE7C6;
    position: relative;

    .topic {
        color: #e9967a;
    }

    .contact-form {
        text-align: start;
        opacity: 0;
        background: #FBE7C6;
        box-shadow: inset 8px 8px 16px #d5c4a8,
            inset -8px -8px 16px #ffffe4, 2px 2px 6px #808080;

        .submit-btn {
            min-width: 5rem;
        }

    }

    // popover-contact
    .assistant-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;

        .ball-assist {
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            background-color: #f39174;
            position: absolute;
            bottom: .5rem;
            left: .5rem;
            background-image: linear-gradient(-45deg, rgba(255, 255, 220, .2) 0%, transparent 100%);
            box-shadow: inset -25px -25px 40px rgb(0 0 0 / 50%);
            z-index: 1;
            overflow: hidden;
            transform: translate(-7.5rem, 0);
            animation: ball-jump 3s linear .25s 1 normal forwards;


            .ball-content {
                height: 100%;
                position: relative;
                cursor: default;

                .ball-message {
                    color: #dee2e6;
                    white-space: nowrap;
                    font-weight: bold;
                    text-shadow: 2px 2px 2px #101010;
                    animation: roll-text 5s linear 2.5s infinite normal forwards;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate((-50%, -50%));
                    width: 7rem;
                    padding: 0;
                    opacity: 0;

                    &:first-of-type {
                        animation-delay: 0s;
                    }
                }
            }
        }

        .popover-assist {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            transform: translate(-5.25rem, 0%) rotate(0);

            .popover-assist-container {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;

                .popover-assist-photo {
                    filter: drop-shadow(2px 2px 2px #000);
                    width: 5rem;
                }

                .popover-card {
                    position: relative;
                    background-color: $popoverColor;
                    box-shadow: inset 8px 8px 16px $popoverShadow, inset -8px -8px 16px #f7bfb1bf, 2px 2px 6px #808080;
                    backdrop-filter: blur(4px);
                    border-radius: 10px;
                    border: 1px solid rgba(255, 255, 255, 0.18);
                    text-align: start;
                    padding: 0.5rem 3rem 0.5rem 0.5rem;
                    transform: rotate(-22.5deg) translateY(-50%) translateX(4rem);
                    opacity: 0;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        top: 3.5rem;
                        left: 0 - $triangleWidth;
                        border-style: solid;
                        border-width: 1rem $triangleWidth 1rem 0;
                        border-color: transparent $popoverShadow transparent transparent;
                    }

                    .close-btn {
                        position: absolute;
                        top: .5rem;
                        right: .5rem;
                        padding: 0.25rem;

                        .close-icon {
                            width: 1.2rem;
                            height: 1rem;

                        }
                    }

                    .popover-text {
                        opacity: 0;

                        .popover-topic {
                            color: #e9ecef;
                            text-shadow: 2px 2px 2px #696969;
                        }
                    }

                    .icon-div {
                        margin: .5rem;
                        white-space: nowrap;

                        .contact-icons {
                            max-width: 1rem;
                            margin: 0 3px;
                        }

                        .icon-label {
                            border-radius: 5px;
                            padding: .25rem;
                        }
                    }
                }
            }
        }
    }

    // .gallery-photos {
    //     position: relative;
    //     flex-grow: 1;
    //     display: contents;
    // }


    //styles for animations when section comes to veiw
    &.active {
        .contact-form {
            animation: contact-card 2s ease-in-out 0s 1 normal forwards;
        }

        .assistant-container {
            .popover-assist {
                animation: open-assist 1s linear 1s 1 forwards normal;

                .popover-card {
                    animation: popover 1s ease-in-out 2s 1 normal forwards;

                    .popover-text {
                        animation: opacity 0.5s linear 3s forwards;
                        opacity: 0;

                    }

                }
            }

            &.secondary-assistant {
                .popover-assist {
                    transform: translate(-19%, 35%) rotate(22.5deg);
                    opacity: 1;
                    animation: close-assist 1s linear 1s 1 normal forwards;

                    .popover-card {
                        animation: close-popover 1s ease-in-out 0s 1 normal forwards;

                        .popover-text {
                            opacity: 0;
                            animation: none;


                        }
                    }
                }
            }

            &.primary-assistant {
                .popover-assist {
                    animation-delay: 0.25s;

                    .popover-card {
                        animation-delay: 1.25s;

                        .popover-text {
                            animation-delay: 2.25s;
                        }
                    }
                }

                .ball-assist {
                    animation: ball-off 1s ease-in-out 0s 1 normal forwards;
                }
            }
        }

    }

    @keyframes contact-card {
        0% {
            animation-timing-function: ease-in;
            opacity: 0;
            transform: translateY(-250px);
        }

        38% {
            animation-timing-function: ease-out;
            opacity: 1;
            transform: translateY(0);
        }

        55% {
            animation-timing-function: ease-in;
            transform: translateY(-65px);
        }

        72% {
            animation-timing-function: ease-out;
            transform: translateY(0);
        }

        81% {
            animation-timing-function: ease-in;
            transform: translateY(-28px);
        }

        90% {
            animation-timing-function: ease-out;
            transform: translateY(0);
        }

        95% {
            animation-timing-function: ease-in;
            transform: translateY(-8px);
        }

        100% {
            animation-timing-function: ease-out;
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes open-assist {
        0% {
            opacity: 1;
            transform: translate(-5.25rem, 0%) rotate(0);
        }

        100% {
            opacity: 1;
            transform: translate(-19%, 35%) rotate(22.5deg);
        }
    }

    @keyframes close-assist {


        0% {
            opacity: 1;
            transform: translate(-19%, 35%) rotate(22.5deg);
        }

        100% {
            opacity: 1;
            transform: translate(-5.25rem, 0%) rotate(0);
        }
    }

    @keyframes popover {
        0% {
            opacity: 1;
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(0, .1);


        }

        50% {
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(1, .1);

        }

        80% {
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(1, .05);

        }

        100% {
            opacity: 1;
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(1);

        }
    }

    @keyframes close-popover {
        0% {
            opacity: 1;
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(1);

        }

        20% {
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(1, .05);

        }

        50% {
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(1, .1);

        }

        100% {
            opacity: 1;
            transform: rotate(-22.5deg) translateY(-50%) translateX(4rem) scale(0, .1);
        }

    }

    @keyframes ball-jump {
        0% {
            transform: translate(-7.5rem, 0);

        }

        15% {
            transform: translate(calc(20vw - 7.5rem), -50vh);

        }

        35% {
            transform: translate(calc(40vw - 7.5rem), 0vh);

        }


        50% {
            transform: translate(calc(60vw - 7.5rem), -25vh);

        }

        65% {
            transform: translate(calc(75vw - 7.5rem), 0vh);

        }

        80% {
            transform: translate(calc(90vw - 7.5rem), -12.5vh);

        }

        100% {
            transform: translate(calc(100vw - 8rem), 0vh);
            animation-timing-function: ease-out;

        }

    }

    @keyframes roll-text {
        0% {
            opacity: 1;
            transform: translate((50%, -50%)) perspective(1000px);
        }

        100% {
            opacity: 1;
            transform: translate((-150%, -50%)) perspective(1000px);
        }
    }

    @keyframes opacity {
        from {
            opacity: 0;

        }

        to {
            opacity: 1;
        }
    }

    @keyframes ball-off {
        from {
            transform: translate(calc(100vw - 8rem), 0vh);

        }

        to {
            transform: translate(-7.5rem, 0);

        }
    }
}