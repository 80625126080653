.certifications {
    background: #212529;

    // box-shadow: inset 8px 8px 16px #1c1f23,
    //     inset -8px -8px 16px #262b2f;
    .section-card {


        &.main-container {
            .gallery-header {
                color: #00aae5;
            }

            .scrolling {
                .item-container {
                    // width: calc(50% - 1rem);
                    width: 95%;

                    @media screen and (min-width: 768px) {
                        width: 47.5%;
                    }

                    .item {
                        border: 1rem solid #000;
                        transform:
                            rotateX(40deg) rotateY(-25deg) rotateZ(25deg);
                        transform-style: preserve-3d;
                        border-radius: 25px;
                        box-shadow:
                            1px 1px 0 1px #f9f9fb,
                            -1px 0 20px 0 rgb(86 204 199 / 50%),
                            20px 20px 20px 0 rgb(23 212 218 / 15%);
                        transition:
                            .4s ease-in-out transform,
                            .4s ease-in-out box-shadow;

                        &:hover {
                            transform:
                                translate3d(0%, 0%, 0);
                            box-shadow: 1px 1px 0 1px #f9f9fb,
                                -1px 0 10px 0 rgb(86 204 199 / 50%),
                                10px 10px 10px 0 rgb(23 212 218 / 15%);

                        }
                    }

                }

            }
        }
    }
}