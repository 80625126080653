.not-found {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .not-found-text {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 5rem;
        display: inline-block;
        color: #c0c0c0;
        background: #212529;
        border-radius: 50%;
        width: 10rem;
        padding: 0;
        line-height: 10rem;
        height: 10rem;
    }
}