// $coding:url("../../../utils/img/coding/07.jpg");

.skills {
    background: #B4F8C8;

    // box-shadow: inset 8px 8px 16px #99d3aa,
    //     inset -8px -8px 16px #cfffe6;
    .topic {
        color: #66cdaa;
    }

    .skills-card {
        color: #f5f5dc;


        .mySkills {
            justify-content: center;

            .skill-col {
                opacity: 0;

                .skill {
                    width: 90%;
                    color: #f5f5dc;
                    font-weight: 700;
                    font-size: 1.2em;
                    margin: 0.5rem;
                    padding: 0.75rem 0;
                    border-radius: 0.5rem;
                    background-color: #306560;
                    box-shadow: 10px 10px 10px black;
                    display: inline-block;
                    // --c: #1095c1;
                    // /* the color */

                    // color: #0000;
                    // background:
                    //     linear-gradient(90deg, #fff 50%, var(--c) 0) calc(100% - var(--_p, 0%))/200% 100%,
                    //     linear-gradient(var(--c) 0 0) 0% 100%/var(--_p, 0%) 100% no-repeat;
                    // -webkit-background-clip: text, padding-box;
                    // background-clip: text, padding-box;
                    // transition: 0.5s;

                    &:hover {
                        // --_p: 100%
                        animation: skill-hover 2s linear 0s infinite forwards normal;
                    }

                    &::marker {
                        unicode-bidi: isolate;
                        font-variant-numeric: tabular-nums;
                        text-transform: none;
                        text-indent: 0px !important;
                        text-align: start !important;
                        text-align-last: start !important;
                    }
                }


            }



        }


    }

    &.active {
        .skill-col {
            animation: skill-move 2s ease 0s 1 normal forwards;
        }
    }

    @keyframes skill-move {
        0% {
            opacity: 0;
            transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
            animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
        }

        60% {
            opacity: 1;
            transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
        }

        100% {
            opacity: 1;
        }

    }

    @keyframes skill-hover {
        25% {
            transform: rotate(3deg);

        }

        75% {
            transform: rotate(-3deg);
        }

        100% {
            transform: rotate(0);
        }

    }
}