.section {
    .main-container {
        height: inherit;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 1.5rem;
        overflow: hidden;


        .scrolling {
            display: block;
            white-space: nowrap;
            overflow-x: scroll;
            scrollbar-width: none;
            scroll-behavior: smooth;
            flex-grow: 1;
            align-items: center;
            opacity: 0;

            &::-webkit-scrollbar {
                display: none;
            }



            .item-container {
                display: inline-block;
                scroll-snap-align: start;
                height: 100%;
                position: relative;

                &:first-child {
                    margin-right: 0;
                }

                &:not(:first-child) {

                    margin-left: 1rem;
                }

                .subcontainer {
                    display: inline-block;
                    position: relative;
                    max-height: 100%;
                    max-width: 100%;
                    border-radius: 1rem;
                    top: 50%;
                    transform: translateY(-50%);

                    .details-paragraph {

                        position: absolute;
                        white-space: normal;
                        bottom: 0%;
                        left: 50%;
                        padding: 5px;
                        transform: translateX(-50%);
                        width: 98%;
                        min-height: 0%;
                        max-height: 0%;
                        z-index: 1;
                        background: #000000e6;
                        color: white;
                        overflow: hidden;
                        transition: min-height 2s ease-in-out, max-height 2s ease-in-out;
                    }

                    .item {
                        max-height: 100%;
                        max-width: 100%;
                        border-radius: 1rem;
                    }

                    &:hover {
                        .details-paragraph {
                            min-height: 40%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }

        // right left arrows 
        .scroll-arrows {
            >button {
                width: 3rem;
                height: 3rem;
                z-index: 1;
                border-radius: 50%;
                background: #ffffff38;
                border: none;
                box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 50%), inset 0px 5px 8px rgb(255 0 224 / 35%), inset 0px -5px 8px rgb(16 255 0 / 35%);
                backdrop-filter: blur(3px);
            }

            .left,
            .right {
                opacity: 0;
                position: absolute;
                top: 50%;

                .arrow-icon {
                    width: 1.5rem;
                }
            }

            .left {
                left: 5px;
            }

            .right {
                right: 5px;
            }
        }


    }

    &.active {
        .scrolling {
            animation: section-body 2s ease 0s 1 normal forwards;
        }

        .scroll-arrows {

            .left {
                animation: left 2s ease 1s 1 normal forwards;
            }

            .right {
                animation: right 2s ease 1s 1 normal forwards;
            }
        }
    }

    // keyframes 
    @keyframes section-body {
        0% {
            animation-timing-function: ease-in;
            opacity: 0;
            transform: translateY(-250px);
        }

        38% {
            animation-timing-function: ease-out;
            opacity: 1;
            transform: translateY(0);
        }

        55% {
            animation-timing-function: ease-in;
            transform: translateY(-65px);
        }

        72% {
            animation-timing-function: ease-out;
            transform: translateY(0);
        }

        81% {
            animation-timing-function: ease-in;
            transform: translateY(-28px);
        }

        90% {
            animation-timing-function: ease-out;
            transform: translateY(0);
        }

        95% {
            animation-timing-function: ease-in;
            transform: translateY(-8px);
        }

        100% {
            animation-timing-function: ease-out;
            transform: translateY(0);
            opacity: 1;
        }
    }


    @keyframes right {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;


        }
    }

    @keyframes left {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;

        }
    }
}