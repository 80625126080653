.home {
    // display: contents;
    height: calc(100vh - 3.5rem);
    position: relative;

    .section {
        width: 100vw;
        scroll-snap-align: start;
        height: 100%;
        padding: 10px;
        // background-color: #000;

        .section-card {
            height: 100%;

            .topic {
                cursor: default;
                opacity: 0;
                text-shadow: 1px 2px 3px #000, 2px 3px 3px #000, 3px 4px 3px #000;
                margin: 0 auto 1rem;
                // font-size: calc(2.375rem + 1.5vw);
                width: fit-content;
                --b: 0.3rem;
                /* the thickness of the line */
                --c: #708090;
                /* the color */

                padding-block: var(--b);
                background:
                    linear-gradient(var(--c) 50%, #000 0) 0% calc(100% - var(--_p, 0%))/100% 200%, linear-gradient(var(--c) 0 0) 0% var(--_p, 0%)/var(--_p, 0%) var(--b) no-repeat;
                -webkit-background-clip: text, padding-box;
                background-clip: text, padding-box;
                transition: .3s var(--_s, 0s) linear, background-size .3s calc(.3s - var(--_s, 0s));

                &:hover {
                    color: #0000;
                    text-shadow: 1px 2px 0px #708090, 1px 2px 3px #000, 2px 3px 4px #000;
                    --_p: 100%;
                    --_s: .3s;

                }
            }

        }

        &.active {
            .topic {
                animation: topics 2s ease 1s 1 normal forwards;
            }
        }
    }


    @keyframes topics {
        // 0% {
        //     opacity: 0;
        //     // transform: translateX(-100%);
        //     transform: scale(1) translateY(50vh);
        // }

        // 50% {
        //     transform: scale(1.5) translateY(0);
        // }

        // 100% {
        //     opacity: 1;
        //     // transform: translateX(0);
        //     transform: scale(1);
        // }
        0% {
            opacity: .5;
            transform: rotateX(70deg);
            transform-origin: top;
        }

        100% {
            opacity: 1;
            transform: rotateX(0deg);
            transform-origin: top;
        }

    }

}